<div class="pt-20">
  <h1 class="text-center text-bold-5">What We Do</h1>
</div>

<div class="flex-grid">
  <div class="col">
    <img [src]="SvgMap.tracking" alt="" width="70px">
    <!-- <dp-svg [src]="SvgMap.f_tracking" w="50"></dp-svg> -->
    <h2 class="feature-title">End to End Tracking</h2>
    <p class="description">Track your multimodal shipments from origin to destination across all carriers</p>
  </div>
  <div class="col">
    <img [src]="SvgMap.visibility" alt="" width="70px">
    <!-- <dp-svg [src]="SvgMap.f_supplyChain" w="50"></dp-svg> -->
    <h2 class="feature-title">Supply Chain Visibility</h2>
    <p class="description">Understand the availability date of your products in every purchase order</p>
  </div>
  <div class="col">
    <img [src]="SvgMap.exception2" alt="" width="70px">
    <h2 class="feature-title">Manage by Exception</h2>
    <p class="description">Subscribe to email notifications for key milestone events and in-transit exceptions</p>
  </div>

  <div class="col">
    <img [src]="SvgMap.insight" alt="" width="70px">
    <h2 class="feature-title">Statistical Insights</h2>
    <p class="description">Gain insights on your logistics network's performance and optimization opportunities</p>
  </div>
</div>